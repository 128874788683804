<script>
    import ProductBadges from "./ProductBadges.svelte";
    import ProductCallout from "./ProductCallout.svelte";
    import ProductFeaturedExtra from "./ProductFeaturedExtra.svelte";
    import ProductImageSlider from "./ProductImageSlider.svelte";
    import ProductRating from "./ProductRating.svelte";
    import ProductPricing from "./ProductPricing.svelte";
    import {getFirstActiveItem} from "./unbxd";
    import ProductFinanceOffer from "./ProductFinanceOffer.svelte";
    import ProductOptions from "./ProductOptions.svelte";
    import ProductTitle from './ProductTitle.svelte';
    import ProductBrand from './ProductBrand.svelte';
    import {SplideSlide} from "./Splide/components/index.js";
    import PricingComponent from "./PricingComponent.svelte";

    export let product;
    export let prank = 0;

    // $: variants = getVariantsAndUpdateKeys(product);
    $: variants = product.variants || [];
    $: activeItem = getFirstActiveItem(product, variants);

    function handleNewProduct(/** @type {{ detail: { productId: string } }} */ event) {
        activeItem = {...variants.find((/** @type {{ productId: string }} */ variant) => variant.productId === event.detail.productId)}
    }

    $: flyInDuration = prank * 25 === 750 ? 750 : prank * 25;
    $: flyOutDuration = prank / 25 === 120 ? 120 : prank / 25;

    $: href = activeItem.productUrl || null;
    $: if (href) {
        let url = new URL(href, location.origin);
        if (url) {
            href = url.pathname;
        }
    }
</script>

<div class="card h-100 d-flex flex-column px-4">
    {#if activeItem.thumbUrl && activeItem.thumbUrl.length > 0}
        <div class="card-img position-relative">
            <a {href}
               unbxdattr="product"
               unbxdparam_sku={product.uniqueId}
               unbxdparam_prank={prank}
               unbxdparam_requestId={product.unbxdFeedId}
               class="d-block card-img-top">
                <img class="responsive card-img-top img-fluid"
                     height={262}
                     width={393}
                     src={activeItem.thumbUrl[0]}
                     alt={activeItem.title}
                />
            </a>
        </div>
    {:else}
        <div class="card-img position-relative bg-dark-10" style="padding-bottom:66.666%;">
            <a {href}
               unbxdattr="product"
               unbxdparam_sku={product.uniqueId}
               unbxdparam_prank={prank}
               unbxdparam_requestId={product.unbxdFeedId}
               class="d-block card-img-top stretched-link">
            </a>
        </div>
    {/if}
    <div class="card-body flex-grow-1 position-relative px-2 pt-3 pb-5 d-flex flex-column">
        <a {href}
           on:click={() => {
       window.gtag('event', 'click', {
        'event_category': 'Unbxd App',
        'event_label': `Product ${activeItem.title}`
       });
   }}
           unbxdattr="product"
           unbxdparam_sku={product.uniqueId}
           unbxdparam_prank={prank}
           unbxdparam_requestId={product.unbxdFeedId}
           class="mt-1 d-block font-weight-bold stretched-link text-center">
            {#if activeItem}
                {#if activeItem.vTitle}
                    {activeItem.vTitle}
                {:else}
                    {activeItem.title}
                {/if}
            {:else}
                {product.title}
            {/if}
        </a>
        <div class="mt-2 mb-2 text-center font-weight-bold">
            {#if product.gwItemType === "collection"}
                {#if activeItem.priceLabel}
                    {activeItem.priceLabel}
                    <br />
                    <PricingComponent
                            discount={activeItem.discount}
                            price={activeItem.price}
                            sellingPrice={activeItem.sellingPrice}
                    />
                {/if}
            {:else}
                {#if activeItem.vPrice}
                    <PricingComponent
                            discount={activeItem.vDiscount}
                            price={activeItem.vPrice}
                            sellingPrice={activeItem.vSellingPrice}
                    />
                {:else}
                    <PricingComponent
                            discount={activeItem.discount}
                            price={activeItem.price}
                            sellingPrice={activeItem.sellingPrice}
                    />
                {/if}
            {/if}
        </div>
    </div>
</div>

<style>
    .card {
        position: relative;
        z-index: 0;
    }
</style>
