export const variantAttributesMap = {
  title: "vTitle",
  uniqueId: "uniqueId",
  thumbUrl: "vThumbUrl",
  price: "vPrice",
  sellingPrice: "vSellingPrice",
  discount: "vDiscount",
  productUrl: "productUrl",
  sizes: "vSizes",
  mattressTypes: "vMattressTypes",
  comfortLevels: "vComfortLevels",
  productId: "productId",
  availability: "availability_text",
  gwItemType: "vGwItemType",
  rating: "rating",
  reviews: "reviews",
  discountMessage: "discountMessage",
  callout: "callout",
  sku: "vSku",
  brands: "brands",
};

export function getVariantsAndUpdateKeys(product) {
  let variants = product.variants || [];
  let variantAttributeKeys = Object.keys(variantAttributesMap);
  for (const [index, variant] of variants.entries()) {
    for (const variantAttributeKey of variantAttributeKeys) {
      let variantAttributeValue = variantAttributesMap[variantAttributeKey];
      if (variantAttributeValue !== variantAttributeKey) {
        let ownValue = Object.getOwnPropertyDescriptor(
          variant,
          variantAttributeValue
        );
        if (ownValue) {
          Object.defineProperty(variant, variantAttributeKey, ownValue);
          delete variant[variantAttributeValue];
        }
      }
    }
    variants[index] = {
      ...product,
      ...variant,
    };
  }
  return variants;
}

export function getFirstActiveItem(product, variants) {
  if (variants.length === 0) return product;
  if (variants.length === 1 || !product.baseProductId) return variants[0];
  for (const variant of variants) {
    if (variant.productId === parseInt(product.baseProductId)) return variant;
  }
  return variants[0];
}

export function getProductOptions(variants, activeItem, optionKeys) {
  let options = {};
  for (const key of optionKeys) {
    if (key in activeItem) {
      let tags = activeItem[key];
      if (Array.isArray(tags)) {
        for (const tag of tags) {
          if (!(key in options))
            options = {
              ...options,
              [key]: [],
            };
          options[key] = [...options[key], tag];
        }
      }
    }
  }
  for (const variant of variants) {
    for (const key of optionKeys) {
      let tags = variant[key];
      if (Array.isArray(tags)) {
        for (const tag of tags) {
          if (!(key in options))
            options = {
              ...options,
              [key]: [],
            };
          options[key] = [...options[key], tag];
        }
        options[key] = [
          ...options[key].filter(function (value, index, self) {
            return self.indexOf(value) === index;
          }),
        ];
        if (key === "sizes" || key === "igSizes") {
          options[key] = [...options[key]].sort(function (a, b) {
            const sizes = [
              "California King",
              "Divided King",
              "Split King",
              "King",
              "Queen",
              "Full",
              "Twin XL",
              "Twin",
            ];
            if (!a || !b) return 0;
            return sizes.indexOf(a) - sizes.indexOf(b);
          });
        } else {
          options[key].sort((a, b) => a.localeCompare(b));
        }
      }
    }
  }
  return options;
}

export function getSelectedProductAttributes(variants, activeItem, optionKeys) {
  let productAttributeKeysValues = {};
  let selectedProductAttributes = {};
  for (let key of optionKeys) {
    if (key === "igSizes") {
      key = "sizes";
    }
    let variantKey = key
      .split(/(?=[A-Z])/)
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1));
    variantKey.unshift("v");
    variantKey = variantKey.join("");
    let tags = [];
    if (variantKey in activeItem) {
      tags = activeItem[variantKey];
    } else if (key in activeItem) {
      tags = activeItem[key];
    }
    if (Array.isArray(tags) && tags.length) {
      const tag = tags.at(0);
      if (!(key in selectedProductAttributes)) {
        selectedProductAttributes = {
          ...selectedProductAttributes,
          [key]: tag,
        };
        productAttributeKeysValues = {
          ...productAttributeKeysValues,
          [key]: tag,
        };
      }
    }
  }
  // let productAttributeKeysValues = {}
  // let selectedProductAttributes = {};
  // for (const key of optionKeys) {
  //   let variantKey = key
  //       .split(/(?=[A-Z])/)
  //       .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
  //   variantKey.unshift("v");
  //   variantKey = variantKey.join("");
  //   let tags = [];
  //   if (variantKey in activeItem) {
  //     tags = activeItem[variantKey];
  //   } else
  //     if (key in activeItem) {
  //     tags = activeItem[key];
  //   }
  //   if (Array.isArray(tags) && tags.length) {
  //     const tag = tags[0];
  //     if (!(key in selectedProductAttributes)) {
  //       selectedProductAttributes = {
  //         ...selectedProductAttributes,
  //         [key]: tag
  //       }
  //       productAttributeKeysValues = {
  //         ...productAttributeKeysValues,
  //         [key]: tag
  //       }
  //     }
  //   }
  // }
  return { productAttributeKeysValues, selectedProductAttributes };
}

// export function getProductIds(variants, productAttributeKeysValues) {
//   let selectableOptions = {};
//   for (const variant of variants) {
//     for (const key of Object.keys(productAttributeKeysValues)) {
//       if (!(key in selectableOptions)) {
//         selectableOptions = {
//           ...selectableOptions,
//           [key]: {
//             ...selectableOptions[key]
//           }
//         }
//       }
//       if (key in variant) {
//         let otherAttributeKeysValues = productAttributeKeysValues;
//         // delete otherAttributeKeysValues[key];
//         const foundIndex = otherAttributeKeysValues.indexOf(key);
//         if (key > -1) {
//           otherAttributeKeysValues.splice(foundIndex, 1);
//         }
//         for (const otherKey of Object.keys(otherAttributeKeysValues)) {
//           if (otherKey in variant) {
//             let tags = variant[otherKey];
//             for (let tag in tags) {
//               if (selectableOptions[key][tag]) continue;
//               selectableOptions[key][tag] = variant.productId
//             }
//           }
//         }
//       }
//     }
//   }
//   return selectableOptions;
// }

export function getSelectableProductIds(variants, productAttributeKeyValues) {
  let selectableOptions = {};
  for (let key in productAttributeKeyValues) {
    if (key === "igSizes") {
      key = "sizes";
    }
    if (!(key in selectableOptions)) {
      selectableOptions = {
        ...selectableOptions,
        [key]: {},
      };
    }
    let vKey = key
      .split(/(?=[A-Z])/)
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1));
    vKey.unshift("v");
    vKey = vKey.join("");
    const attributeKeys = Object.keys(productAttributeKeyValues);
    const foundKeyIndex = attributeKeys.indexOf(key);
    if (foundKeyIndex > -1) {
      attributeKeys.splice(foundKeyIndex, 1);
    }
    for (let otherKey of attributeKeys) {
      let vOtherKey = otherKey
        .split(/(?=[A-Z])/)
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1));
      vOtherKey.unshift("v");
      vOtherKey = vOtherKey.join("");
      for (let variant of variants) {
        if (vOtherKey in variant) {
          let otherVtag = variant[vOtherKey][0];
          if (vKey in variant && variant[vKey].length) {
            let vTag = variant[vKey][0];
            if (otherVtag === productAttributeKeyValues[otherKey]) {
              if (!(vTag in selectableOptions[key])) {
                selectableOptions = {
                  ...selectableOptions,
                  [key]: {
                    ...selectableOptions[key],
                    [vTag]: variant.productId,
                  },
                };
              }
            }
          }
        } else if (otherKey in variant) {
          let otherVtag = variant[otherKey][0];
          if (otherKey in variant && variant[otherKey].length) {
            if (vKey in variant) {
              let vTag = variant[vKey][0];
              if (otherVtag === productAttributeKeyValues[otherKey]) {
                if (!(vTag in selectableOptions[key])) {
                  selectableOptions = {
                    ...selectableOptions,
                    [key]: {
                      ...selectableOptions[key],
                      [vTag]: variant.productId,
                    },
                  };
                }
              }
            }
          }
        }
      }
    }
    for (let variant of variants) {
      if (vKey in variant) {
        let vTag = variant[vKey][0];
        if (!(vTag === productAttributeKeyValues[vKey])) {
          selectableOptions = {
            ...selectableOptions,
            [key]: {
              ...selectableOptions[key],
              [vTag]: variant.productId,
            },
          };
        }
      }
    }
  }
  selectableOptions = clearEmpties(selectableOptions);
  return selectableOptions;
}

/**
 * @param {Object} o
 * @return {Object}
 */
function clearEmpties(o) {
  for (let k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue;
    }
    if (Object.keys(o[k]).length === 0) {
      delete o[k];
    }
  }
  return o;
}

// @TODO: Look into how we are going to transform this for vanilla...
export const FacetTypes = {
  RANGE: "range",
  MULTISELECT: "multilevel",
  TEXT: "text",
};

export function transformFacets(data) {
  let baseFacets = data.facets || {};
  for (const [key, facets] of Object.entries(baseFacets)) {
    baseFacets[key].list = facets.list.map((facet) => ({
      ...facet,
      facetType: key,
    }));
  }
  const flattenedFacets = Object.values(baseFacets)
    .map((facet) => facet.list)
    .flat()
    .sort((a, b) => {
      return a.position - b.position;
    });
  return {
    ...data,
    facets: flattenedFacets,
  };
}

export async function unbxdResults(
  params = new URLSearchParams(),
  page = 1,
  rows = 30,
  { apiKey, siteKey, productType }
) {
  let baseUrl = `https://search.unbxd.io/${apiKey}/${siteKey}`;
  const baseParams = [
    ["page", page.toString()],
    ["rows", rows.toString()],
    ["version", "V2"],
    ["facet.version", "V2"],
    ["variants", "true"],
    ["variants.count", "15"],
    ["spellcheck", "true"],
    ["pagetype", "boolean"],
    ["facet.multiselect", "true"],
    ["fields", ""],
  ];
  let isSearch;
  if (productType === "CATEGORY") {
    baseUrl += "/category";
    baseParams.push(["p", window.UnbxdAnalyticsConf["page"]]);
  } else if (productType === "CLEARANCE") {
    baseUrl += "/category";
    baseParams.push(["p", window.UnbxdAnalyticsConf["page"]]);
    baseParams.push(["sort", "modifiedAt desc"]);
  } else {
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get("q");
    window.UnbxdAnalyticsConf["query"] = q;
    // if (!params.has("q")) {
    baseParams.push(["q", q]);
    // }
    baseUrl += "/search";
    isSearch = q;
  }
  const allParams = new URLSearchParams([...baseParams]);
  const filteredParams = new URLSearchParams();
  for (let [key, value] of params.entries()) {
    if (value === "") continue;
    filteredParams.append(key, value);
  }
  let paramsObject = {};
  filteredParams.forEach((value, key) => {
    if (key in paramsObject) {
      paramsObject[key].push(value);
    } else {
      paramsObject[key] = [value];
    }
  });
  Object.keys(paramsObject).map((key) => {
    if (key === "q") {
      allParams.delete("q");
      allParams.append("q", paramsObject[key]);
    } else if (key === "categoryPath") {
      allParams.append("category-filter", paramsObject[key]);
    } else if (
      key === "rating" ||
      key === "pricing" ||
      key === "sellingPrice" ||
      key === "height" ||
      key === "width" ||
      key === "depth"
    ) {
      // allParams.append("filter", `${key}_uFilter:${paramsObject[key].join("")}`)
      allParams.append("filter", `${key}:${paramsObject[key].join("")}`);
    } else if (key !== "rows" && key !== "page" && key !== "sort") {
      allParams.append(
        "filter",
        paramsObject[key].map((i) => `${key}_uFilter:"${i}"`).join(" OR ")
      );
    } else {
      allParams.append(key, paramsObject[key].join(""));
    }
  });
  const url = `${baseUrl}?${allParams.toString()}`;
  const response = await fetch(url);
  let data = await response.json();
  data = transformFacets(data);
  data.isSearch = isSearch;
  data.activeFacets = activeFacets(paramsObject, data);
  let unbxdRequestId = response.headers.get("x-request-id");
  let payload = {};
  if (isSearch) {
    payload.query = isSearch;
  } else {
    payload.page = window.UnbxdAnalyticsConf["page"];
    payload.page_type = "boolean";
  }
  if (unbxdRequestId) {
    payload.requestId = unbxdRequestId;
  }
  if (data.activeFacets) {
    let facets = [];
    for (let facet of data.activeFacets) {
      let facetName = facet.filterField;
      if (!facets[facetName]) facets[facetName] = [];
      if (Array.isArray(facet.values)) {
        facets[facetName] = facet.values.filter((value) => {
          if (typeof value === "string") {
            return value;
          } else if (typeof value === "object" && "name" in value) {
            return value.name;
          }
        });
      } else {
        // console.log(facet);
      }
    }
    payload.facets = facets;
  }
  if (window.Unbxd && typeof Unbxd.track === "function") {
    Unbxd.track("facets", payload);
  } else {
    console.error("unbxdAnalytics.js is not loaded");
  }
  return data;
}

function activeFacets(paramsObject = {}, data = {}) {
  if (!data.facets) return [];
  let facets = data.facets;
  let activeFacets = [];
  Object.keys(paramsObject).map((key) => {
    if (key === "sort" && paramsObject[key].length > 0) {
      let string = paramsObject[key].join(", ");
      let activeFilter = string;
      if (new RegExp(/(sellingPrice)/gi).test(string)) {
        activeFilter = "Price";

        if (new RegExp(/(asc)/gi).test(string)) {
          activeFilter += ": Low to High";
        } else if (new RegExp(/(desc)/gi).test(string)) {
          activeFilter += ": High to Low";
        }
      }
      activeFacets.push({
        facetName: "sort",
        displayName: "Sort By",
        activeFilter,
      });
      return;
    }
    let foundFacet = facets.find((facet) => {
      let tester = facet.filterField ?? facet.facetName;
      return tester.includes(key);
    });
    if (foundFacet) {
      let lastParam = paramsObject[key].join(", ");
      let splitParams = lastParam.split(">");
      let activeFilter = splitParams[splitParams.length - 1]
        .replace(/[\[\]]/g, "")
        .split(" TO ")
        .map((x) => {
          if (
            new RegExp(/(price)/gi).test(foundFacet.facetName) &&
            parseInt(x)
          ) {
            return "$" + x;
          }
          if (
            new RegExp(/(\(in\))/gi).test(foundFacet.facetName) &&
            parseInt(x)
          ) {
            return x + ' "';
          }
          return x;
        })
        .join(" TO ");

      activeFacets.push({
        ...foundFacet,
        activeFilter,
      });
    }
  });
  return activeFacets;
}
