<script>
  export let discount;
  export let sellingPrice;
  export let price;
  export let discountMessage;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
</script>

{#if discount}
  <span class="font-size-xs text-gray-350 text-decoration-line-through">
    {formatter.format(price)}
  </span>
  {#if discountMessage}
    <span class="gw-text-sale font-size-xs d-inline-block">{discountMessage}</span>
    <span class="gw-text-sale d-block">
      {formatter.format(sellingPrice)}
    </span>
    {:else}
    <span class="gw-text-sale">
      {formatter.format(sellingPrice)}
    </span>
  {/if}
{:else}
  <span class="font-size-xs gw-text-primary">
    {formatter.format(sellingPrice)}
  </span>
{/if}
