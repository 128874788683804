import Recs from "./Recs.svelte";

const targets = document.querySelectorAll(".unbxd_recs");
targets.forEach((target) => {
  const pageType = target.getAttribute("data-unbxd-page-type") || "HOME";
  const widget = target.getAttribute("data-unbxd-widget") || "widget1";
  const productId = target.getAttribute("data-unbxd-product-id");
  const category = target.getAttribute("data-unbxd-category");
  const siteKey =
    target.getAttribute("data-unbxd-site-key") ||
    "ss-unbxd-gcp-Gardner-White--DEV-8241643047600";
  const apiKey =
    target.getAttribute("data-unbxd-api-key") ||
    "b5b0f92ca5d9faab9cb709595753a76a";
  const uid = target.getAttribute("data-unbxd-uid");

  const app = new Recs({
    target,
    widget,
    props: {
      pageType,
      widget,
      productId,
      category,
      siteKey,
      apiKey,
      uid
    },
  });
});

export default {};
