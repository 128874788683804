<script>
    import UnbxdRecItem from "./lib/UnbxdRecItem.svelte";
    import {Splide, SplideSlide} from "./lib/Splide/index.mjs"

    export let pageType;
    export let widget;

    export let siteKey;
    export let apiKey;
    export let uid;

    export let productId;

    export let category;

    let baseUrlString = `https://g-recommendations.unbxd.io/v2.0/${apiKey}/${siteKey}/items?pageType=${pageType}&uid=${uid}`;

    // let unbxdRequestId = sessionStorage.getItem('unbxdRequestId');
    //
    // if (!unbxdRequestId) {
    //     fetch(baseUrlString)
    //         .then(response => {
    //             const unbxdRequestId = response.headers.get('x-request-id');
    //             sessionStorage.setItem('unbxdRequestId', unbxdRequestId);
    //         })
    //         .catch(error => console.error(error));
    // }
    //
    // unbxdRequestId = sessionStorage.getItem('unbxdRequestId');
    // $: console.log(unbxdRequestId);
    // url.searchParams.append('uid', unbxdRequestId);


    let url = new URL(baseUrlString);

    const customFields = [
        'title',
        'discount',
        'sellingPrice',
        'price',
        'thumbUrl',
        'reviews',
        'brands',
        'callout',
        'featuredExtra',
        'productUrl',
        'uniqueId',
        'availability_text',
        'rating',
        'financeOfferId',
        'financeType',
        'financeDuration',
        'financePayment',
        'financeDuration'
    ];

    // for (const field of customFields) {
    url.searchParams.append('fields', customFields.join(','));
    // }

    if (productId) {
        url.searchParams.append('id', productId);
    }

    if (category) {
        url.searchParams.append('catlevel1Name', category);
    }

    async function getRecommendations(widget) {
        const response = await fetch(url.toString());
        const data = await response.json();
        return data[widget];
    }

    $: recWidget = {};
    $: getRecommendations(widget).then((data) => recWidget = data);

    $: widgetTitle = recWidget.widgetTitle;

</script>


{#if Object.keys(recWidget).length > 0 && recWidget.recommendations.length > 0}
    <div class="unbxd-recs-widget">
        {#if widgetTitle}
            <h2 class="mb-10">{widgetTitle}</h2>
        {/if}
        <Splide options="{{
            arrows: true,
            navigation: true,
            resetProgress: true,
            rewind: true,
            fixedWidth: '300px',
            autoPlay: true,
            classes: {
                pagination: 'splide__pagination flickity-page-dots',
                page: 'splide__pagination__page dot',
            }
        }}" class="unbxd-recs-widget__items splide flickity-page-dots-progress gw-splide splide-fade">
            {#each recWidget.recommendations as item, idx (idx)}
                <SplideSlide class="unbxd-recs-widget__item">
                    <UnbxdRecItem
                            product={item}
                            prank={idx}
                    />
                </SplideSlide>
            {/each}
        </Splide>
    </div>
{/if}

<style>
    .unbxd-recs-widget {
        margin: 20px auto;
        width: 100%;
    }

    .unbxd-recs-widget h2 {
        text-align: center;
    }

    /*.unbxd-recs-widget__items {*/
    /*    display: flex;*/
    /*    flex-wrap: wrap;*/
    /*    justify-content: space-between;*/
    /*}*/

    /*.unbxd-recs-widget__item {*/
    /*    width: 23%;*/
    /*    margin-bottom: 20px;*/
    /*}*/
</style>