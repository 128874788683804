import {forOwn} from '../forOwn/forOwn.js';
import {isObject} from '../isObject/isObject.js';

/**
 * Recursively merges source properties to the object.
 * Be aware that this method does not merge arrays. They are just duplicated by `slice()`.
 *
 * @param object - An object to merge properties to.
 * @param source - A source object to merge properties from.
 *
 * @return A new object with merged properties.
 */
export function merge(object, source) {
    const merged = object;
    forOwn(source, (value, key) => {
        if (Array.isArray(value)) {
            merged[key] = value.slice();
        } else if (isObject(value)) {
            merged[key] = merge(isObject(merged[key]) ? merged[key] : {}, value);
        } else {
            merged[key] = value;
        }
    });
    return merged;
}
